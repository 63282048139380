@use 'sass:math';

@mixin screen-lg {
  @media (min-width: $gb_breakpointLG) {
    @content;
  }
}

@mixin screen-md {
  @media (min-width: $gb_breakpointMD) {
    @content;
  }
}

@mixin screen-max-md {
  @media (max-width: $gb_breakpointMD) {
    @content;
  }
}

@mixin only-screen-md {
  @media (min-width: $gb_breakpointSM) and (max-width: $gb_breakpointMD) {
    @content;
  }
}

@mixin screen-sm {
  @media (min-width: $gb_breakpointSM) {
    @content;
  }
}

@mixin only-screen-sm {
  @media (min-width: $gb_breakpointXS) and (max-width: $gb_breakpointSM) {
    @content;
  }
}

@mixin below-screen-sm {
  @media (max-width: $gb_breakpointSM) {
    @content;
  }
}

@mixin screen-xs {
  @media (min-width: $gb_breakpointXS) {
    @content;
  }
}

@mixin below-screen-xs {
  @media (max-width: $gb_breakpointXS) {
    @content;
  }
}

@mixin set-responsive-font-sizes($xs, $sm, $md) {
  font-size: calc-pixel($xs);
  line-height: calc-line-height($xs);

  @include screen-sm {
    font-size: calc-pixel($sm);
    line-height: calc-line-height($sm);
  }

  @include screen-md {
    font-size: calc-pixel($md);
    line-height: calc-line-height($md);
  }
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

/**
 * Example usage:
 *
 *   .button:before {
 *      @include web20-icon("arrow-right");
 *   }
 */

@mixin web20-icon($icon-name) {
  @extend %web20-icon-#{$icon-name};
  @extend %web20-icon;
}

/**
 * Example usage:
 *
 *   h1 {
 *      @include hyphens();
 *   }
 */
@mixin hyphens() {
  -ms-word-break: normal;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/**
 * Example usage:
 *
 *   h1 {
 *      @include word-wrap();
 *   }
 */
@mixin word-wrap() {
  @include hyphens();
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/**
 * Example usage:
 *
 *  div {
 *    @include content-columns(3);
 *  }
 */
@mixin content-columns($columnCount) {
  -webkit-column-count: $columnCount;
  -moz-column-count: $columnCount;
  column-count: $columnCount;
}

/**
 * Example usage:
 *  $value: avoid | auto | initial | inherit
 *
 *  div {
 *     @include column-break-inside(avoid);
 *  }
 */
@mixin column-break-inside($value) {
  -webkit-column-break-inside: $value;
  page-break-inside: $value;
  break-inside: $value;
}

/**
 * Example usage:
 *
 *  div {
 *     @include aspect-ratio(16, 9);
 *  }
 */
@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: math.div($height, $width) * 100%;
  }

  > .image-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/**
 * Example usage: flex-flow
 *
 *  div {
 *     @include flex-flow();
 *  }
 */
@mixin flex-flow() {
  flex-flow: row wrap;
  -ms-flex-flow: row wrap;
}

@mixin playIcon($sizeMobile, $sizeDesktop, $hasHover: false, $zindex: inherit) {
  $mobileIconSize: math.div($sizeMobile, 2) - 5;
  $desktopIconSize: math.div($sizeDesktop, 2) - 10;

  &::after {
    @include web20-icon('play');
    @if $hasHover {
      background: rgba(255, 255, 255, 0.3);
    } @else {
      background: rgba(255, 255, 255, 0.5);
    }
    border-radius: 50%;
    border: 1px solid $gb_black;
    display: block;
    font-size: calc-pixel($mobileIconSize);
    height: calc-pixel($sizeMobile);
    left: 50%;
    line-height: calc-pixel($sizeMobile);
    position: absolute;
    text-align: center;
    text-indent: 3px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc-pixel($sizeMobile);
    z-index: $zindex;
    @include screen-sm {
      width: calc-pixel($sizeDesktop);
      height: calc-pixel($sizeDesktop);
      font-size: calc-pixel($desktopIconSize);
      line-height: calc-pixel($sizeDesktop);
      text-indent: 4px;
    }
  }
  @if $hasHover {
    &:hover {
      &::after {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

/**
 * Example usage: line-clamp
 * maximum number of lines
 * characters are cut off and end with three dots ...
 *
 *  div {
 *     @include line-clamp($integer);
 *  }
 */
@mixin line-clamp($integer) {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $integer;
  overflow: hidden;
}

/**
 * Example usage: bullet-points-spacing
 *
 *  div {
 *     @include bullet-points-spacing();
 *  }
 */
@mixin bullet-points-spacing() {
  ol,
  ul {
    padding-left: 1.5rem;

    li {
      padding-bottom: 0;
    }

    ol,
    ul {
      margin-top: 0px;
    }
  }
}
