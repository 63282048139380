@use 'sass:math';

// Spacings
$spacing-xxxxs: 0.5625rem;
$spacing-xxxs: 0.875rem;
$spacing-xxs: 1rem;
$spacing-xs: 1.25rem;
$spacing-s: 1.5rem;
$spacing-m: 1.875rem;
$spacing-l: 2.25rem;
$spacing-xl: 2.5rem;
$spacing-xxl: 3.75rem;
$spacing-xxxl: 5.625rem;
$spacing-section-top: 5rem;
$spacing-section-bottom: 6.25rem;

/* deprecated */
$spacing-gdds-section: 5rem 0;
$spacing-gdds-section-mobile: 1.25rem 0 2.5rem 0;
/* end deprecated */

$spacing-gdds-section-large: 5rem 0; // 80/80
$spacing-gdds-section-small: 2rem 0; // 32/32
$spacing-gdds-section-mobile-large: 2.5rem 0; // 40/40
$spacing-gdds-section-mobile-small: 1.25rem 0; // 20/20

$spacing-nordics-mobile: 4rem 0; // 64
$spacing-nordics-desktop: 5rem 0; // 80

// Shadows
$shadow-flyout: inset 24px 0 24px -24px rgba(0, 0, 0, 0.1);
$shadow-flyout-below: 0 5px 0.75rem -5px rgba(0, 0, 0, 0.25);
$shadow-depth-main-nav: 0 0.3125rem 0.75rem -0.3125rem rgba(0, 0, 0, 0.25);
$shadow-depth-1:
  0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.12),
  0 0.0625rem 0.125rem rgba(0, 0, 0, 0.24);
$shadow-depth-2:
  0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16),
  0 0.1875rem 0.375rem rgba(0, 0, 0, 0.23);
$shadow-depth-3:
  0 0.625rem 1.25rem rgba(0, 0, 0, 0.19),
  0 0.375rem 0.375rem rgba(0, 0, 0, 0.23);
$shadow-depth-4:
  0 0.875rem 1.75rem rgba(0, 0, 0, 0.25),
  0 625rem 625rem rgba(0, 0, 0, 0.22);
$shadow-depth-5:
  0 1.1875rem 2.375rem rgba(0, 0, 0, 0.3),
  0 0.9375rem 0.75rem rgba(0, 0, 0, 0.22);
$gb_boxShadowDefault: 0.0625rem 0.0625rem 0.0625rem rgba(0, 0, 0, 0.15) !default;

// Widths
$gb_sizeUnit: 'rem' !default; // px || rem

$gb_breakpointXL: 90rem !default;
$gb_breakpointLG: 80rem !default;
$gb_breakpointMD: 64rem !default;
$gb_breakpointSM: 48rem !default;
$gb_breakpointXS: 20rem !default;

$gb_containerMinWidth: 20rem !default;
$gb_containerMaxWidth: 80rem !default;
$gb_headerHeightSm: 3.75rem !default;
$gb_headerHeightMd: 6.875rem !default;
$gb_mainNavigationWidth: 15rem !default;

// Fonts
$gb_fontFamilyBase: 'AktivGrotesk', helvetica, arial, sans-serif !default;
$gb_webfontPath: '/fonts/grotesk/' !default;
$gb_nordicsFontQuickbrush: 'QuickbrushBaltics', helvetica, arial, sans-serif !default;
$gb_nordicsFontPath: '/fonts/quickbrush' !default;
$gb_lineHeightBase: 1.5 !default;
$gb_fontSizeBase: 0.875rem !default;

// Images
$gb_imagePath: '../images/' !default;

// Animations
$gb_transitionTiming: ease-in-out !default;
$gb_transitionFast: 0.15s !default;
$gb_transitionNormal: 0.3s !default;
$gb_transitionSlow: 0.5s !default;

$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;

$font-size-small: 0.75rem;
$font-size-medium: 1.25rem;
$font-size-subtitle: 1.125rem;
$font-size-body-1: 1rem;
$font-size-body-2: 0.875rem;
$font-size-label-1: 0.8125rem;
$font-size-label-2: 0.75rem;

$line-height-subtitle: 1.5rem;
$line-height-body-1: 1.5rem;
$line-height-body-2: 1.42rem;
$line-height-label-1: 1rem;
$line-height-label-2: 1rem;

$text-transform-cap: capitalize;
$text-transform-up: uppercase;

$gb_basePath: '';

// Foundation
// see _settings.scss in foundation-size
// --------------------------

// --------------------------
// paths
$gb_iconPath: '#{$gb_basePath}/fonts/icons/' !default;
$gb_webfontPath: '#{$gb_basePath}/fonts/grotesk/' !default;
$gb_nordicsFontPath: '#{$gb_basePath}/fonts/grotesk/' !default;
$gb_imagePath: '#{$gb_basePath}/images/' !default;

// --------------------------
// global variables
$global-width: $gb_containerMaxWidth;
$global-lineheight: $gb_lineHeightBase;
$grid-container: $gb_containerMaxWidth;

// --------------------------
// Colors
$gb_white: #ffffff;
$gb_white_00: rgba(255, 255, 255, 0);
$gb_white_40: rgba(255, 255, 255, 0.4);
$gb_white_70: rgba(255, 255, 255, 0.7);
$gb_white_80: rgba(255, 255, 255, 0.8);
$gb_black: #000000;
$gb_black_00: rgba(0, 0, 0, 0);
$gb_black_08: rgba(0, 0, 0, 0.08);
$gb_black_26: rgba(0, 0, 0, 0.26);
$gb_black_70: rgba(0, 0, 0, 0.7);
$gb_black_80: rgba(0, 0, 0, 0.8);
$gb_black_82: rgba(0, 0, 0, 0.82);
$gb_cc_actions: #46446b;

// --------------------------
// Brand Colors

// PLATFORM
$gb_gdds_cl17: #376eb4; // CL17 (A1)
$gb_gdds_cl18: #0a5a96; // CL18 (A3)
$gb_gdds_cl19: #004673; // CL19 (A4)

// Platform
$platform-primary: #{$gb_gdds_cl18};
$platform-primary-light: #{$gb_gdds_cl17};
$platform-primary-dark: #{$gb_gdds_cl19};

// NORD Colors
$heroPine: #26513b; // CL17 (A1)
$springLeaves: #4b7155; // CL18 (A3)
$laurel: #6c906e; // CL19 (A4)

// Nordics
$nord-primary: #{$springLeaves};
$nord-primary-light: #{$heroPine};
$nord-primary-dark: #{$laurel};
$nord-secondary-emerald: #4ba5a0;

// KOLO Colors
$teal: #1e818a; // CL17 (A1)
$ocean: #0a636b; // CL18 (A3)
$rainForest: #0e4b53; // CL19 (A4)

// Kolo
$kolo-primary: #{$ocean};
$kolo-primary-light: #{$teal};
$kolo-primary-dark: #{$rainForest};
$kolo-secondary-emerald: #4ba5a0;

// TWYFORD Colors
$P626: #245c4e; // CL17 (A1)
$P625: #4e7f71; // CL18 (A3)
$P624: #779e91; // CL19 (A4)

// TWYFORD
$twyford-primary: #{$P625};
$twyford-primary-light: #{$P626};
$twyford-primary-dark: #{$P624};
$twyford-secondary-emerald: #4ba5a0;

// Tile hover colors
$tile-black-hover: #202020;
$tile-green-hover: #234b36;

// --------------------------
// Neutral Colors
$gb_grey_025: #fcfcfc;
$gb_grey_050: #f7f7f7;
$gb_grey_055: #f5f5f5;
$gb_grey_060: #f1f1f1;
$gb_grey_075: #e6e6e6;
$gb_grey_100: #e4e4e4;
$gb_grey_150: #d9d9d9;
$gb_grey_200: #cccccc;
$gb_grey_300: #a1afbf;
$gb_grey_400: #999999;
$gb_grey_600: #707070;
$gb_grey_650: #666666;
$gb_grey_700: #5c5c5c;
$gb_grey_750: #4e4e50;
$gb_grey_800: #3d3d3d;

// GDDS
$gb_gdds_cl03: #f7f7f7;
$gb_gdds_cl09: #cccccc;
$gb_gdds_cl10b: #292525;
$gb_gdds_cl10: #b4b4b4;
$gb_gdds_cl12: #707070;
$gb_gdds_cl13: #666666;
$gb_gdds_cl14: #5c5c5c;
$gb_gdds_cl15: #3d3d3d;
$gb_gdds_cl15_50: rgba(61, 61, 61, 0.5);
$gb_gdds_cl16: rgba(0, 0, 0, 0.15);

$gb_gdds_success: #008800;
$gb_gdds_warning: #f07800;
$gb_gdds_error: #ce0000;

$gb_yellow: #eedaa0;
$gb_brown: #5a503c;
$gb_turquoise: #466973;
$gb_dark_forest: #41503a;

// Campus specific colors
$gb_bgcolor_webinar: #eedaa0;
$gb_bgcolor_elearning: #b1efde;
$gb_bgcolor_webcast: #d9d9d9;
$gb_bgcolor_event: #f5b5b5;
$gb_bgcolor_seminar: #c4dced;
$gb_textcolor_expiration_date: #ce0000;
$gb_capacitycolor_green: #008800;
$gb_capacitycolor_orange: #f07800;
$gb_section_separator: #d6d6d6;

// --------------------------
// Utility Colors
$gb_light-grey-blue: #b5cddf;
$gb_dark-grey-blue: #23465a;
$gb_grey-blue: #84909d;
$gb_saturated_green: #008d1b;
$gb_positive_green: #24b900;
$gb_brand-utility-warning-light: #f4eaa6;
$gb_brand-utility-warning: #e19b3c;
$gb_brand-utility-negative-light: #f09292;
$gb_brand-utility-negative: #dd4b39;
$gb_brand-utility-positive-light: #b1e3af;
$gb_brand-utility-positive: #9bc444;

$brand-highlight-light: #e19b3c;
$brand-highlight: #f49831;
$brand-highlight-dark: #ab6d03;

$fs_release-state-released: #56b525;
$fs_release-state-changed: #b5834d;
$fs_release-state-workflow: #943233;

$foundation-palette: (
  primary: red,
  secondary: $gb_black,
  success: $gb_brand-utility-positive,
  warning: $gb_brand-utility-warning,
  alert: $gb_brand-utility-negative,
);

// Nordics Colors
$storm: #566777;
$wave: #7d8fae;
$sky: #c1cfe0;
$norway: #9abd90;
$cliff: #4d4e53;
$stone: #b3b7bc;
$porcelain: #f1f2f2;
$earth-clay: #735e4e;
$earth-sand: #cfae86;
$light-teal: #73c8b9;

$color-backdrop: rgba(0, 0, 0, 0.6);
$color-black-36: rgba(0, 0, 0, 0.36);
$color-black-06: rgba(0, 0, 0, 0.06);

$default-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
$box-shadow-inner-top-bottom:
  inset 0px 15px 12px -10px $gb_grey_100,
  inset 0px -15px 12px -10px $gb_grey_100;
$box-shadow-focus: 0 0 7px rgba(0, 0, 0, 0.25);
$box-shadow-layout: 0 0 12px rgba(0, 0, 0, 0.28); // Used for header and footer
$box-shadow-main-nav-top: 0 12px 12px -12px rgba(0, 0, 0, 0.15);
$box-shadow-main-nav-top-left: 5px 5px 5px -5px rgba(0, 0, 0, 0.15);
$box-shadow-main-nav-left: inset 5px 0 5px -5px rgba(0, 0, 0, 0.15);
$box-shadow-responsive-table-right: 1px 3px 10px $gb_grey_200;
$box-shadow-carousel-hover: 0 13px 16px rgba(0, 0, 0, 0.4);
$box-shadow-dropdown: 0 2px 7px rgba(0, 0, 0, 0.25);

$body-background: $gb_white;
$body-font-color: $gb_black;
$body-font-family: $gb_fontFamilyBase;
$body-antialiased: true;

// --------------------------
// breakpoints
$breakpoints: (
  small: 0,
  medium: $gb_breakpointSM,
  large: $gb_breakpointMD,
  xlarge: $gb_breakpointLG,
  xxlarge: $gb_breakpointXL,
);

// --------------------------
// components
$country-selector-height: 30rem;
$header-height-worst-case: 10.625rem;
$header-height-mobile: 3.75rem;
$sticky-header-height: 4.375rem;
$cookie-disclaimer-height: 6.25rem;
$search-bar-height: 10rem;

$section-padding: 5.625rem;
$section-margin: 3.75rem;

// --------------------------
// z-index
$z-min: 0;
$z-dropdown: 20;
$z-header-backdrop: 10;
$z-tooltip: 15;
$z-scroll-to-top: 20;
$z-search-bar: 20;
$z-sticky-header: 30;
$z-main-nav-bar: 30;
$z-country-selector: 40;
$z-meta-header: 50;
$z-cookie-disclaimer: 9999999999;
$z-lightbox: 100;
$z-max: 100;

// --------------------------
// transition
$transition-very-fast: 0.1s;
$transition-faster: 0.2s;
$transition-fast: 0.3s;
$transition-medium: 0.5s;
$transition-slow: 0.8s;
$transition-timing-ease-out: ease-out;
$transition-timing-ease-in-out: ease-in-out;
$transition-timing-linear: linear;
$transition-timing: $transition-timing-ease-out;

// --------------------------
// border
$border-black: 1px solid $gb_black;
$border_transparent: 1px solid transparent;
$border-solid-background: 1px solid $gb_grey_200;
$border-grey: 1px solid $gb_grey_100;
$backdrop-color: rgba(0, 0, 0, 0.6);

// --------------------------
// gdds typography - line height - h1
$gdds-line-height-h1-xl: 3.75rem;
$gdds-line-height-h1-l: 3.25rem;
$gdds-line-height-h1-m: 2.75rem;
$gdds-line-height-h1-s: 2.25rem;
// gdds typography - font size - h1
$gdds-font-size-h1-xl: 2.875rem;
$gdds-font-size-h1-l: 2.625rem;
$gdds-font-size-h1-m: 2.25rem;
$gdds-font-size-h1-s: 1.75rem;
