/* stylelint-disable */
@font-face {
  font-family: "geberitPlatfomWeb20IconFont";
  src: url("#{$gb_iconPath}/geberitPlatfomWeb20IconFont.eot?a31e2fd51c69ead777476cef4649d28a?#iefix") format("embedded-opentype"),
url("#{$gb_iconPath}/geberitPlatfomWeb20IconFont.woff?a31e2fd51c69ead777476cef4649d28a") format("woff"),
url("#{$gb_iconPath}/geberitPlatfomWeb20IconFont.ttf?a31e2fd51c69ead777476cef4649d28a") format("truetype"),
url("#{$gb_iconPath}/geberitPlatfomWeb20IconFont.svg?a31e2fd51c69ead777476cef4649d28a#geberitPlatfomWeb20IconFont") format("svg");
}

.web20-icon {
  line-height: 1;
}

%web20-icon {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    font-family: 'geberitPlatfomWeb20IconFont' !important;
    font-style: normal;
    font-weight: normal !important;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

[class*=web20-icon],
[class^=web20-icon],
.web20-icon {
  &::before,
  &::after {
     @extend %web20-icon
  }
}

%web20-icon-3d-bath-planner {
  content: '\f101';
}
.web20-icon-3d-bath-planner::before {
  @extend %web20-icon-3d-bath-planner;
}
%web20-icon-account {
  content: '\f102';
}
.web20-icon-account::before {
  @extend %web20-icon-account;
}
%web20-icon-alert {
  content: '\f103';
}
.web20-icon-alert::before {
  @extend %web20-icon-alert;
}
%web20-icon-anchor {
  content: '\f104';
}
.web20-icon-anchor::before {
  @extend %web20-icon-anchor;
}
%web20-icon-arrow-link-bold-down {
  content: '\f105';
}
.web20-icon-arrow-link-bold-down::before {
  @extend %web20-icon-arrow-link-bold-down;
}
%web20-icon-arrow-link-bold-left {
  content: '\f106';
}
.web20-icon-arrow-link-bold-left::before {
  @extend %web20-icon-arrow-link-bold-left;
}
%web20-icon-arrow-link-bold-right {
  content: '\f107';
}
.web20-icon-arrow-link-bold-right::before {
  @extend %web20-icon-arrow-link-bold-right;
}
%web20-icon-arrow-link-bold-up {
  content: '\f108';
}
.web20-icon-arrow-link-bold-up::before {
  @extend %web20-icon-arrow-link-bold-up;
}
%web20-icon-arrow-link-light-down {
  content: '\f109';
}
.web20-icon-arrow-link-light-down::before {
  @extend %web20-icon-arrow-link-light-down;
}
%web20-icon-arrow-link-light-left {
  content: '\f10a';
}
.web20-icon-arrow-link-light-left::before {
  @extend %web20-icon-arrow-link-light-left;
}
%web20-icon-arrow-link-light-right {
  content: '\f10b';
}
.web20-icon-arrow-link-light-right::before {
  @extend %web20-icon-arrow-link-light-right;
}
%web20-icon-arrow-link-light-up {
  content: '\f10c';
}
.web20-icon-arrow-link-light-up::before {
  @extend %web20-icon-arrow-link-light-up;
}
%web20-icon-attachment {
  content: '\f10d';
}
.web20-icon-attachment::before {
  @extend %web20-icon-attachment;
}
%web20-icon-autofilled {
  content: '\f10e';
}
.web20-icon-autofilled::before {
  @extend %web20-icon-autofilled;
}
%web20-icon-available-seats {
  content: '\f10f';
}
.web20-icon-available-seats::before {
  @extend %web20-icon-available-seats;
}
%web20-icon-b2b {
  content: '\f110';
}
.web20-icon-b2b::before {
  @extend %web20-icon-b2b;
}
%web20-icon-b2c-bigger {
  content: '\f111';
}
.web20-icon-b2c-bigger::before {
  @extend %web20-icon-b2c-bigger;
}
%web20-icon-b2c {
  content: '\f112';
}
.web20-icon-b2c::before {
  @extend %web20-icon-b2c;
}
%web20-icon-back {
  content: '\f113';
}
.web20-icon-back::before {
  @extend %web20-icon-back;
}
%web20-icon-bathroom-products {
  content: '\f114';
}
.web20-icon-bathroom-products::before {
  @extend %web20-icon-bathroom-products;
}
%web20-icon-breadcrumb-arrow {
  content: '\f115';
}
.web20-icon-breadcrumb-arrow::before {
  @extend %web20-icon-breadcrumb-arrow;
}
%web20-icon-breadcrumb-home {
  content: '\f116';
}
.web20-icon-breadcrumb-home::before {
  @extend %web20-icon-breadcrumb-home;
}
%web20-icon-bullet {
  content: '\f117';
}
.web20-icon-bullet::before {
  @extend %web20-icon-bullet;
}
%web20-icon-calendar {
  content: '\f118';
}
.web20-icon-calendar::before {
  @extend %web20-icon-calendar;
}
%web20-icon-campus-cost {
  content: '\f119';
}
.web20-icon-campus-cost::before {
  @extend %web20-icon-campus-cost;
}
%web20-icon-campus-mail {
  content: '\f11a';
}
.web20-icon-campus-mail::before {
  @extend %web20-icon-campus-mail;
}
%web20-icon-campus-phone {
  content: '\f11b';
}
.web20-icon-campus-phone::before {
  @extend %web20-icon-campus-phone;
}
%web20-icon-campus-pin {
  content: '\f11c';
}
.web20-icon-campus-pin::before {
  @extend %web20-icon-campus-pin;
}
%web20-icon-chat {
  content: '\f11d';
}
.web20-icon-chat::before {
  @extend %web20-icon-chat;
}
%web20-icon-check {
  content: '\f11e';
}
.web20-icon-check::before {
  @extend %web20-icon-check;
}
%web20-icon-chevron-large-down {
  content: '\f11f';
}
.web20-icon-chevron-large-down::before {
  @extend %web20-icon-chevron-large-down;
}
%web20-icon-chevron-large-left {
  content: '\f120';
}
.web20-icon-chevron-large-left::before {
  @extend %web20-icon-chevron-large-left;
}
%web20-icon-chevron-large-right {
  content: '\f121';
}
.web20-icon-chevron-large-right::before {
  @extend %web20-icon-chevron-large-right;
}
%web20-icon-chevron-large-up {
  content: '\f122';
}
.web20-icon-chevron-large-up::before {
  @extend %web20-icon-chevron-large-up;
}
%web20-icon-chevron-small-down {
  content: '\f123';
}
.web20-icon-chevron-small-down::before {
  @extend %web20-icon-chevron-small-down;
}
%web20-icon-chevron-small-left {
  content: '\f124';
}
.web20-icon-chevron-small-left::before {
  @extend %web20-icon-chevron-small-left;
}
%web20-icon-chevron-small-right {
  content: '\f125';
}
.web20-icon-chevron-small-right::before {
  @extend %web20-icon-chevron-small-right;
}
%web20-icon-chevron-small-up {
  content: '\f126';
}
.web20-icon-chevron-small-up::before {
  @extend %web20-icon-chevron-small-up;
}
%web20-icon-close {
  content: '\f127';
}
.web20-icon-close::before {
  @extend %web20-icon-close;
}
%web20-icon-collect {
  content: '\f128';
}
.web20-icon-collect::before {
  @extend %web20-icon-collect;
}
%web20-icon-compare {
  content: '\f129';
}
.web20-icon-compare::before {
  @extend %web20-icon-compare;
}
%web20-icon-contact-form {
  content: '\f12a';
}
.web20-icon-contact-form::before {
  @extend %web20-icon-contact-form;
}
%web20-icon-cost-person {
  content: '\f12b';
}
.web20-icon-cost-person::before {
  @extend %web20-icon-cost-person;
}
%web20-icon-course-date {
  content: '\f12c';
}
.web20-icon-course-date::before {
  @extend %web20-icon-course-date;
}
%web20-icon-disk {
  content: '\f12d';
}
.web20-icon-disk::before {
  @extend %web20-icon-disk;
}
%web20-icon-download-book {
  content: '\f12e';
}
.web20-icon-download-book::before {
  @extend %web20-icon-download-book;
}
%web20-icon-download-excel {
  content: '\f12f';
}
.web20-icon-download-excel::before {
  @extend %web20-icon-download-excel;
}
%web20-icon-download-neutral {
  content: '\f130';
}
.web20-icon-download-neutral::before {
  @extend %web20-icon-download-neutral;
}
%web20-icon-download-pdf {
  content: '\f131';
}
.web20-icon-download-pdf::before {
  @extend %web20-icon-download-pdf;
}
%web20-icon-download-zip {
  content: '\f132';
}
.web20-icon-download-zip::before {
  @extend %web20-icon-download-zip;
}
%web20-icon-download {
  content: '\f133';
}
.web20-icon-download::before {
  @extend %web20-icon-download;
}
%web20-icon-duration {
  content: '\f134';
}
.web20-icon-duration::before {
  @extend %web20-icon-duration;
}
%web20-icon-elearning {
  content: '\f135';
}
.web20-icon-elearning::before {
  @extend %web20-icon-elearning;
}
%web20-icon-event {
  content: '\f136';
}
.web20-icon-event::before {
  @extend %web20-icon-event;
}
%web20-icon-external-link {
  content: '\f137';
}
.web20-icon-external-link::before {
  @extend %web20-icon-external-link;
}
%web20-icon-facebook {
  content: '\f138';
}
.web20-icon-facebook::before {
  @extend %web20-icon-facebook;
}
%web20-icon-file-with-type {
  content: '\f139';
}
.web20-icon-file-with-type::before {
  @extend %web20-icon-file-with-type;
}
%web20-icon-file {
  content: '\f13a';
}
.web20-icon-file::before {
  @extend %web20-icon-file;
}
%web20-icon-flipbook {
  content: '\f13b';
}
.web20-icon-flipbook::before {
  @extend %web20-icon-flipbook;
}
%web20-icon-functions {
  content: '\f13c';
}
.web20-icon-functions::before {
  @extend %web20-icon-functions;
}
%web20-icon-gas {
  content: '\f13d';
}
.web20-icon-gas::before {
  @extend %web20-icon-gas;
}
%web20-icon-gdds-check {
  content: '\f13e';
}
.web20-icon-gdds-check::before {
  @extend %web20-icon-gdds-check;
}
%web20-icon-gdds-close {
  content: '\f13f';
}
.web20-icon-gdds-close::before {
  @extend %web20-icon-gdds-close;
}
%web20-icon-gdds-document {
  content: '\f140';
}
.web20-icon-gdds-document::before {
  @extend %web20-icon-gdds-document;
}
%web20-icon-gdds-download {
  content: '\f141';
}
.web20-icon-gdds-download::before {
  @extend %web20-icon-gdds-download;
}
%web20-icon-gdds-home {
  content: '\f142';
}
.web20-icon-gdds-home::before {
  @extend %web20-icon-gdds-home;
}
%web20-icon-globe {
  content: '\f143';
}
.web20-icon-globe::before {
  @extend %web20-icon-globe;
}
%web20-icon-hard-drive {
  content: '\f144';
}
.web20-icon-hard-drive::before {
  @extend %web20-icon-hard-drive;
}
%web20-icon-heating {
  content: '\f145';
}
.web20-icon-heating::before {
  @extend %web20-icon-heating;
}
%web20-icon-hover_icon-hover-image {
  content: '\f146';
}
.web20-icon-hover_icon-hover-image::before {
  @extend %web20-icon-hover_icon-hover-image;
}
%web20-icon-industry {
  content: '\f147';
}
.web20-icon-industry::before {
  @extend %web20-icon-industry;
}
%web20-icon-info-circle-full {
  content: '\f148';
}
.web20-icon-info-circle-full::before {
  @extend %web20-icon-info-circle-full;
}
%web20-icon-info-circle-outline {
  content: '\f149';
}
.web20-icon-info-circle-outline::before {
  @extend %web20-icon-info-circle-outline;
}
%web20-icon-information {
  content: '\f14a';
}
.web20-icon-information::before {
  @extend %web20-icon-information;
}
%web20-icon-inspiration {
  content: '\f14b';
}
.web20-icon-inspiration::before {
  @extend %web20-icon-inspiration;
}
%web20-icon-inspirator {
  content: '\f14c';
}
.web20-icon-inspirator::before {
  @extend %web20-icon-inspirator;
}
%web20-icon-installation-systems {
  content: '\f14d';
}
.web20-icon-installation-systems::before {
  @extend %web20-icon-installation-systems;
}
%web20-icon-language {
  content: '\f14e';
}
.web20-icon-language::before {
  @extend %web20-icon-language;
}
%web20-icon-lens-zoom-in {
  content: '\f14f';
}
.web20-icon-lens-zoom-in::before {
  @extend %web20-icon-lens-zoom-in;
}
%web20-icon-lens-zoom-out {
  content: '\f150';
}
.web20-icon-lens-zoom-out::before {
  @extend %web20-icon-lens-zoom-out;
}
%web20-icon-linkedin {
  content: '\f151';
}
.web20-icon-linkedin::before {
  @extend %web20-icon-linkedin;
}
%web20-icon-location {
  content: '\f152';
}
.web20-icon-location::before {
  @extend %web20-icon-location;
}
%web20-icon-locator-pos {
  content: '\f153';
}
.web20-icon-locator-pos::before {
  @extend %web20-icon-locator-pos;
}
%web20-icon-marker-hotel-pin {
  content: '\f154';
}
.web20-icon-marker-hotel-pin::before {
  @extend %web20-icon-marker-hotel-pin;
}
%web20-icon-marker-pin {
  content: '\f155';
}
.web20-icon-marker-pin::before {
  @extend %web20-icon-marker-pin;
}
%web20-icon-menu-burger {
  content: '\f156';
}
.web20-icon-menu-burger::before {
  @extend %web20-icon-menu-burger;
}
%web20-icon-minus {
  content: '\f157';
}
.web20-icon-minus::before {
  @extend %web20-icon-minus;
}
%web20-icon-more-active {
  content: '\f158';
}
.web20-icon-more-active::before {
  @extend %web20-icon-more-active;
}
%web20-icon-more {
  content: '\f159';
}
.web20-icon-more::before {
  @extend %web20-icon-more;
}
%web20-icon-nav-bathroom-systems {
  content: '\f15a';
}
.web20-icon-nav-bathroom-systems::before {
  @extend %web20-icon-nav-bathroom-systems;
}
%web20-icon-nav-collections {
  content: '\f15b';
}
.web20-icon-nav-collections::before {
  @extend %web20-icon-nav-collections;
}
%web20-icon-nav-drainage-systems {
  content: '\f15c';
}
.web20-icon-nav-drainage-systems::before {
  @extend %web20-icon-nav-drainage-systems;
}
%web20-icon-nav-installation {
  content: '\f15d';
}
.web20-icon-nav-installation::before {
  @extend %web20-icon-nav-installation;
}
%web20-icon-nav-supply-systems {
  content: '\f15e';
}
.web20-icon-nav-supply-systems::before {
  @extend %web20-icon-nav-supply-systems;
}
%web20-icon-nav-tools {
  content: '\f15f';
}
.web20-icon-nav-tools::before {
  @extend %web20-icon-nav-tools;
}
%web20-icon-new {
  content: '\f160';
}
.web20-icon-new::before {
  @extend %web20-icon-new;
}
%web20-icon-newsletter {
  content: '\f161';
}
.web20-icon-newsletter::before {
  @extend %web20-icon-newsletter;
}
%web20-icon-online-catalogue {
  content: '\f162';
}
.web20-icon-online-catalogue::before {
  @extend %web20-icon-online-catalogue;
}
%web20-icon-open-book {
  content: '\f163';
}
.web20-icon-open-book::before {
  @extend %web20-icon-open-book;
}
%web20-icon-options {
  content: '\f164';
}
.web20-icon-options::before {
  @extend %web20-icon-options;
}
%web20-icon-pencil-filled {
  content: '\f165';
}
.web20-icon-pencil-filled::before {
  @extend %web20-icon-pencil-filled;
}
%web20-icon-picture {
  content: '\f166';
}
.web20-icon-picture::before {
  @extend %web20-icon-picture;
}
%web20-icon-pinterest {
  content: '\f167';
}
.web20-icon-pinterest::before {
  @extend %web20-icon-pinterest;
}
%web20-icon-play {
  content: '\f168';
}
.web20-icon-play::before {
  @extend %web20-icon-play;
}
%web20-icon-plus {
  content: '\f169';
}
.web20-icon-plus::before {
  @extend %web20-icon-plus;
}
%web20-icon-printer {
  content: '\f16a';
}
.web20-icon-printer::before {
  @extend %web20-icon-printer;
}
%web20-icon-reload {
  content: '\f16b';
}
.web20-icon-reload::before {
  @extend %web20-icon-reload;
}
%web20-icon-search-document {
  content: '\f16c';
}
.web20-icon-search-document::before {
  @extend %web20-icon-search-document;
}
%web20-icon-search {
  content: '\f16d';
}
.web20-icon-search::before {
  @extend %web20-icon-search;
}
%web20-icon-seminar {
  content: '\f16e';
}
.web20-icon-seminar::before {
  @extend %web20-icon-seminar;
}
%web20-icon-service-chat {
  content: '\f16f';
}
.web20-icon-service-chat::before {
  @extend %web20-icon-service-chat;
}
%web20-icon-service-clock {
  content: '\f170';
}
.web20-icon-service-clock::before {
  @extend %web20-icon-service-clock;
}
%web20-icon-service-fax {
  content: '\f171';
}
.web20-icon-service-fax::before {
  @extend %web20-icon-service-fax;
}
%web20-icon-service-mail {
  content: '\f172';
}
.web20-icon-service-mail::before {
  @extend %web20-icon-service-mail;
}
%web20-icon-service-phone {
  content: '\f173';
}
.web20-icon-service-phone::before {
  @extend %web20-icon-service-phone;
}
%web20-icon-service-pin-outline {
  content: '\f174';
}
.web20-icon-service-pin-outline::before {
  @extend %web20-icon-service-pin-outline;
}
%web20-icon-share {
  content: '\f175';
}
.web20-icon-share::before {
  @extend %web20-icon-share;
}
%web20-icon-shipbuilding {
  content: '\f176';
}
.web20-icon-shipbuilding::before {
  @extend %web20-icon-shipbuilding;
}
%web20-icon-shoppingcart {
  content: '\f177';
}
.web20-icon-shoppingcart::before {
  @extend %web20-icon-shoppingcart;
}
%web20-icon-sprinkler {
  content: '\f178';
}
.web20-icon-sprinkler::before {
  @extend %web20-icon-sprinkler;
}
%web20-icon-target-group {
  content: '\f179';
}
.web20-icon-target-group::before {
  @extend %web20-icon-target-group;
}
%web20-icon-thumb-down {
  content: '\f17a';
}
.web20-icon-thumb-down::before {
  @extend %web20-icon-thumb-down;
}
%web20-icon-thumb-up {
  content: '\f17b';
}
.web20-icon-thumb-up::before {
  @extend %web20-icon-thumb-up;
}
%web20-icon-tools {
  content: '\f17c';
}
.web20-icon-tools::before {
  @extend %web20-icon-tools;
}
%web20-icon-trashcan {
  content: '\f17d';
}
.web20-icon-trashcan::before {
  @extend %web20-icon-trashcan;
}
%web20-icon-twitter {
  content: '\f17e';
}
.web20-icon-twitter::before {
  @extend %web20-icon-twitter;
}
%web20-icon-underfloor-heating {
  content: '\f17f';
}
.web20-icon-underfloor-heating::before {
  @extend %web20-icon-underfloor-heating;
}
%web20-icon-user {
  content: '\f180';
}
.web20-icon-user::before {
  @extend %web20-icon-user;
}
%web20-icon-watch-list-add {
  content: '\f181';
}
.web20-icon-watch-list-add::before {
  @extend %web20-icon-watch-list-add;
}
%web20-icon-watch-list-added {
  content: '\f182';
}
.web20-icon-watch-list-added::before {
  @extend %web20-icon-watch-list-added;
}
%web20-icon-watchlist {
  content: '\f183';
}
.web20-icon-watchlist::before {
  @extend %web20-icon-watchlist;
}
%web20-icon-water {
  content: '\f184';
}
.web20-icon-water::before {
  @extend %web20-icon-water;
}
%web20-icon-webcast {
  content: '\f185';
}
.web20-icon-webcast::before {
  @extend %web20-icon-webcast;
}
%web20-icon-webinar {
  content: '\f186';
}
.web20-icon-webinar::before {
  @extend %web20-icon-webinar;
}
%web20-icon-xing {
  content: '\f187';
}
.web20-icon-xing::before {
  @extend %web20-icon-xing;
}
