@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';
@import '../../../styles/vendor/icons';

// Lightbox GDDS Styling
:global {
  .c-lightbox__overlay {
    background-color: transparent;
    @include screen-sm {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }

  .c-lightbox__title {
    flex-direction: row;
    margin-top: 1.25rem;
    position: relative;

    @include screen-sm {
      margin-top: 0;
    }
  }
}

.campusLightbox {
  @include screen-sm {
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 23.875rem !important;
    margin-top: 1.25rem;
    width: 38.5rem !important;
    padding: 2.75rem 0.813rem 0 2.5rem !important;
  }

  :global {
    .c-lightbox__close {
      color: $gb_black;
      margin-bottom: 0;
      position: absolute;
      right: -12px;
      top: -23px;

      @include screen-sm {
        margin-bottom: 1.875rem;
        right: 0;
      }

      span {
        display: none;
      }
    }
  }

  h4 {
    margin-top: 1.813rem;
    text-transform: unset;
    width: 90%;

    @include screen-sm {
      margin-top: 0;
    }
  }
}

// Layer Content

.campusParticipantsList {
  ul {
    margin-top: 0;
    padding-left: 20px;

    li {
      font-size: 16px;
      padding-bottom: 4px;
      text-align: left;
    }
  }
}

.listTwoCols {
  @include screen-sm {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    li {
      width: 50%;
    }
  }
}
