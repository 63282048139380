@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';
@import '../../../styles/vendor/icons';
@import '../CourseTeaser/courseTeaser.module.scss';
@import './confirmationButton.module.scss';

.headline h3 {
  margin-bottom: 2rem;
}

.cancelCourse {
  :global {
    .back-to-course {
      margin: 1rem 0 2.5rem 0;

      a::before {
        content: '\f10a';
      }
    }
  }
}

.courseHeadlines {
  @include screen-md {
    padding-right: 5rem;
    width: 53rem;
  }

  h4 {
    margin-bottom: 1.75rem;
    @include line-clamp(3);

    @include screen-sm {
      @include line-clamp(2);
    }

    @include screen-md {
      @include line-clamp(1);
    }
  }
}

.courseTeaserWrapper {
  margin-top: 4rem;
}

.courseTeaser {
  @include screen-sm {
    margin-left: 1.563rem;
    padding: 1.75rem 4.5rem 0 4.5rem;
  }

  :global(.course-inner-wrapper) {
    @include screen-sm {
      padding-bottom: 2rem;
    }

    @include screen-md {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 0;
    }
  }

  :global(.course-information) {
    position: relative;
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }

  :global(.item) {
    margin-bottom: 0;
    width: max-content;

    :global(b) {
      margin-left: 0.7rem;
    }
  }
}

.itemContent {
  margin-left: 1.5rem;
  display: flex;
  margin-right: 15px;

  span:first-of-type {
    display: flex;
    margin-right: 15px;

    &::after {
      content: '–';
      margin-left: 15px;
    }
  }
}

.participants {
  @include screen-sm {
    margin-top: 3.4rem;
  }
}

.participantsList {
  border-bottom: 1px solid $gb_grey_150;
  padding-bottom: 2rem;

  @include screen-sm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.participantsListItem > div {
  flex-wrap: wrap;

  // overwrite actual gdds styles because of line-clamp
  label {
    @include line-clamp(1);
    line-height: 1.25rem;
  }

  > div {
    margin-right: 0;

    label {
      color: $gb_grey_800;
      font-size: 14px;
    }

    @include screen-sm {
      width: 13.8rem;
    }

    @include screen-md {
      width: 17.25rem;
    }
  }
}

.cancellationForm {
  border-bottom: none;
}
